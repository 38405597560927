html,
body,
#root
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.545);
    overflow: hidden;
    margin: 0;
    font-family: 'Cabin', sans-serif;
}

.nav{
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Cabin', sans-serif;
    
}

.back{
    position: relative;
    left: -50px;
    width: 60px;
    height: 45px;
    border: none;
    outline: none;
    padding: 7px 7px 7px 7px;
    border-radius: 6px;
    color: #030303;
    font-size: 15px;
    /* background-color: transparent; */
    box-shadow: 3px 3px 10px rgba(0,0,0,1),
    -1px -1px 6px rgba(255, 255, 255, 0.4);
    font-family: 'Cabin', sans-serif;
    
}

.back:active {
    border: 2px solid transparent;
    color: #000000;
    box-shadow: 3px 3px 10px rgba(0,0,0,1),
    -1px -1px 6px rgba(255, 255, 255, 0.4),
    inset 3px 3px 10px rgba(0,0,0,1),
    inset -1px -1px 6px rgba(255, 255, 255, 0.4);
    font-family: 'Cabin', sans-serif;
  }


.next{
    position: relative;
    left: 50px;
    width: 60px;
    height: 45px;
    border: none;
    outline: none;
    padding: 7px 7px 7px 7px;
    /* padding-left: */
    border-radius: 6px;
    color: #000000;
    font-size: 15px;
    /* background-color: transparent; */
    box-shadow: 3px 3px 10px rgba(0,0,0,1),
    -1px -1px 6px rgba(255, 255, 255, 0.4);
}

.next:active {
    border: 2px solid transparent;
    color: #000000;
    box-shadow: 3px 3px 10px rgba(0,0,0,1),
    -1px -1px 6px rgba(255, 255, 255, 0.4),
    inset 3px 3px 10px rgba(0,0,0,1),
    inset -1px -1px 6px rgba(255, 255, 255, 0.4);
  }

.current{
    position: absolute;
    top: 80%;
    top: 0px;
    /* bottom: -40px; */
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000000;
    /* background-color: #fff; */
    font-family: 'Cabin', sans-serif;
}

.form{
    position: absolute;
    top: 89%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    max-width: 100vw;
    font-family: 'Cabin', sans-serif;
}

.submit{
    position: relative;
    /* left: 75px; */
    width: 80px;
    height: 45px;
    border: none;
    outline: none;
    padding: 7px 7px 7px 7px;
    border-radius: 6px;
    color: #000;
    font-size: 15px;
    /* background-color: transparent; */
    box-shadow: 3px 3px 10px rgba(0,0,0,1),
    -1px -1px 6px rgba(0, 0, 0, 0.4);
    font-family: 'Cabin', sans-serif;
}

.input{
    position: relative;
    /* left: 60px; */
    width: 160px;
    height: 45px;
    border: none;
    outline: none;
    padding: 0px 7px 0px 7px;
    border-radius: 6px;
    color: #000;
    font-size: 15px;
    /* background-color: transparent; */
    box-shadow: 3px 3px 10px rgba(0,0,0,1),
    -1px -1px 6px rgba(0, 0, 0, 0.4);
    font-family: 'Cabin', sans-serif;
}

.input:focus {
    border: 2px solid transparent;
    color: #000000;
    box-shadow: 3px 3px 10px rgba(0,0,0,1),
    -1px -1px 6px rgba(0, 0, 0, 0.4),
    inset 3px 3px 10px rgba(0,0,0,1),
    inset -1px -1px 6px rgba(0, 0, 0, 0.4);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.icon{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 96%;
    left: 48%;
    transform: translate(-50%, -50%);
    z-index: 2;
    max-width: 100vw;
    font-family: 'Cabin', sans-serif;
    align-items: center;
    width: 15px;
    height: 15px;
    border: none;
    outline: none;
    padding: 7px 7px 7px 7px;
    border-radius: 50px;
    color: #000000;
    font-size: 15px;
    /* background-color: transparent; */
    background-color: #fff;
    box-shadow: 3px 3px 10px rgba(0,0,0,1),
    -1px -1px 6px rgba(255, 255, 255, 0.4);
    font-family: 'Cabin', sans-serif;
}

.random{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 96%;
    left: 52%;
    transform: translate(-50%, -50%);
    z-index: 2;
    max-width: 100vw;
    font-family: 'Cabin', sans-serif;
    align-items: center;
    width: 15px;
    height: 15px;
    border: none;
    outline: none;
    padding: 7px 7px 7px 7px;
    border-radius: 50px;
    color: #000000;
    font-size: 15px;
    /* background-color: transparent; */
    background-color: #fff;
    box-shadow: 3px 3px 10px rgba(0,0,0,1),
    -1px -1px 6px rgba(255, 255, 255, 0.4);
    font-family: 'Cabin', sans-serif;
}

.icon:active{
    border: 2px solid transparent;
    color: #666666;
    box-shadow: 3px 3px 10px rgba(0,0,0,1),
    -1px -1px 6px rgba(255, 255, 255, 0.4),
    inset 3px 3px 10px rgba(0,0,0,1),
    inset -1px -1px 6px rgba(255, 255, 255, 0.4);
}

.random:active{
    border: 2px solid transparent;
    color: #666666;
    box-shadow: 3px 3px 10px rgba(0,0,0,1),
    -1px -1px 6px rgba(255, 255, 255, 0.4),
    inset 3px 3px 10px rgba(0,0,0,1),
    inset -1px -1px 6px rgba(255, 255, 255, 0.4);
}

.container{
    position: absolute;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    font-family: 'Cabin', sans-serif;
    top: 50%;
    left: 50%;
    padding: 10px;
    /* padding-bottom: 20px; */
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    height:fit-content;
    border: 2px solid transparent;
    color: #fff;
    box-shadow: 3px 3px 10px rgba(0,0,0,1),
    -1px -1px 6px rgba(255, 255, 255, 0.4),
    inset 3px 3px 10px rgba(0,0,0,1),
    inset -1px -1px 6px rgba(255, 255, 255, 0.4);
}

.contact{
    transform: scale(125%);
    display: flex;
    justify-content: space-around;
    align-items:stretch;
    margin-top: 60px; 
    margin-bottom: 20px;
    padding: 10px;
}

.close{
    position: relative;
    top: 60%;
    left: 98%;
    /* padding: 10px; */
    /* transform: translate(-50%, -50%); */
}

.close:hover{
    cursor: pointer;
}

.contact:hover{
    cursor: pointer;
}

a {
    text-decoration: none;
    color: #fff;
  }

@media only screen and (max-width: 600px) {
    .form{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 89%;
        left: 50%;
        padding: 0;
        transform: translate(-50%, -50%);
        z-index: 2;
        /* max-width: 100vw; */
        align-items: center;
        width: 300px;
        font-family: 'Cabin', sans-serif;
        /* border-style: dashed;
        border-color: red;
        border-width: 1px; */
    }

    .submit{
        width: 60px;
        
    }

    .icon{
        position: absolute;
        top: 89%;
        left: 10%;
    }

    .random{
        position: absolute;
        top: 89%;
        left: 90%;
    }

    .container{
        position: absolute;
        /* display: flex; */
        justify-content: center;
        align-items: center;
        font-family: 'Cabin', sans-serif;
        top: 50%;
        left: 50%;
        padding: 10px;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 80%;
        height:80%;
        border: 2px solid transparent;
        color: #fff;
        overflow: scroll;
        box-shadow: 3px 3px 10px rgba(0,0,0,1),
        -1px -1px 6px rgba(255, 255, 255, 0.4),
        inset 3px 3px 10px rgba(0,0,0,1),
        inset -1px -1px 6px rgba(255, 255, 255, 0.4);
    }

    .contact{
        transform: scale(125%);
        display: flex;
        justify-content: space-evenly;
        align-items:stretch;
        top: 90%; 
    }

    .close{
        position: relative;
        top: 0%;
        left: 95%;
        /* padding-top: 50px; */
        /* padding: 10px; */
        /* transform: translate(-50%, -50%); */
    }
}

